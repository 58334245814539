import React from "react";
import {
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Select,
  Card,
  CardBody,
  Checkbox,
  CardFooter,
  Stack,
} from "@chakra-ui/react";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

const SegundoPasoRegistroFijo = (props) => {
  const {
    loading,
    setActiveStep,
    activeStep,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    textAccionesInmediatas,
    setTextAccionesInmediatas,
    setFieldValue,
    usuarios,
  } = props;

  const handleCaracteres = (event, setFieldValue) => {
    const text = event.target.value;
    setTextAccionesInmediatas(text);
    setFieldValue("accionesRegistroEventos", text);
  };
  const charCount = textAccionesInmediatas.length;

  return (
    <Stack w="100%">
      <HStack w="100%" justifyContent="space-around" pb={3}>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Acciones Inmediatas</FormLabel>
          <Card w="xs" variant="unstyled" bg="brand.fondos_secundarios">
            <CardBody>
              <Textarea
                w="xs"
                placeholder="Descripcion"
                name="accionesRegistroEventos"
                onChange={(e) => handleCaracteres(e, setFieldValue)}
                value={values.accionesRegistroEventos}
                maxLength="250"
                resize="none"
              />
            </CardBody>
            <CardFooter placeSelf="end" color="brand.gris_medio">
              {charCount}/250
            </CardFooter>
          </Card>
        </FormControl>
        <FormControl w="2xs" isRequired placeSelf="start">
          <FormLabel fontWeight="semibold">
            Responsable Acción Inmediatas
          </FormLabel>
          <Input
            w="2xs"
            type="text"
            placeholder="Resp."
            name="responsableAccionRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.responsableAccionRegistroEventos}
          />
        </FormControl>
        <FormControl w="2xs" placeSelf="start">
          <FormLabel fontWeight="semibold">
            ¿El servicio quedó reestablecido?
          </FormLabel>
          <HStack>
            <Checkbox
              name="servicioReestRegistroEventos"
              onChange={handleChange}
              isChecked={values.servicioReestRegistroEventos}
            >
              SI
            </Checkbox>
          </HStack>
        </FormControl>
      </HStack>
      <HStack w="100%" justifyContent="space-around" pb={3}>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Detectó</FormLabel>
          <Select
            w="2xs"
            name="detectoRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.detectoRegistroEventos}
            placeholder="Seleccione detector"
          >
            {usuarios
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((usuario) => (
                <option value={usuario.idUsuario} key={usuario.idUsuario}>
                  {usuario.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Responsable registro</FormLabel>
          <Select
            w="2xs"
            name="responsableRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Seleccione responsable"
            value={values.responsableRegistroEventos}
          >
            {usuarios
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((usuario) => (
                <option value={usuario.idUsuario} key={usuario.idUsuario}>
                  {usuario.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha/Hora del registro</FormLabel>
          <Input
            w="2xs"
            type="datetime-local"
            name="fechaAccionRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fechaAccionRegistroEventos}
          />
        </FormControl>
      </HStack>
      <HStack w="100%" justifyContent="space-between" px={10} py={5}>
        <Button
          fontSize={12}
          fontWeight="semibold"
          color="brand.naranja"
          border="1px solid #FF8200"
          leftIcon={<MdNavigateBefore fontSize="20px" />}
          variant="outline"
          onClick={() => setActiveStep(activeStep - 1)}
          isDisabled={loading}
          _hover={{ bg: "brand.naranja", color: "white" }}
        >
          Atras
        </Button>
        <Button
          fontSize={12}
          fontWeight="semibold"
          bg="brand.naranja"
          color="white"
          rightIcon={<MdNavigateNext fontSize="20px" />}
          variant="solid"
          form="FormularioRegistroEvento"
          onClick={handleSubmit}
          isDisabled={loading}
          _hover={{ bg: "white", color: "brand.naranja" }}
        >
          Continuar
        </Button>
      </HStack>
    </Stack>
  );
};

export default SegundoPasoRegistroFijo;
