import React, { useEffect } from "react";
import { ChakraProvider, extendTheme, useToast, Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginValidateAD } from "./pages/auth/helpers/api.helper";
import { loginSuccess } from "./redux/actions/action.user";
import Routing from "./pages/routing/Routing";
import { BrowserRouter } from "react-router-dom";
import "@fontsource/noto-sans-jp";
import Alertas from "./pages/config/Alertas";

function App() {
  const theme = extendTheme({
    colors: {
      brand: {
        naranja: "#FF8200",
        azul_fondo: "#004B87",
        azul_text: "#005C97",
        verde: "#007749",
        rojo: "#E41E2D",
        gris_primario: "#38485C",
        gris_secundario: "#505E70",
        gris_medio: "#9EAAB8",
        fondos_primarios: "#FFFFFF",
        fondos_secundarios: "#F3F6F9",
      },
    },
    fonts: {
      title: `Roboto, sans-serif`,
      text: `Open-sans, sans-serif`,
    },
    breakpoints: {
      sm: "320px",
      md: "770px",
      lg: "960px",
      xl: "1450px",
      "2xl": "2560px",
    },
    components: {
      Switch: {
        baseStyle: {
          thumb: {
            bg: "white",
          },
          track: {
            bg: "red.500",

            _checked: {
              bg: "green.500",
              position: "relative",
              _before: {
                content: "'✓'",
                position: "absolute",
                left: "25%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "14px",
                color: "white",
              },
            },
            position: "relative",
            _before: {
              content: "'X'",
              position: "absolute",
              left: "70%",
              top: "50%",
              transform: "translate(-40%, -40%)",
              fontSize: "14px",
              color: "white",
            },
          },
        },
      },
    },
  });

  const toast = useToast();
  const dispatch = useDispatch();
  const { instance, accounts, inProgress } = useMsal();

  const isAutenticated = useIsAuthenticated();

  const handleLoginValidateADResult = (res) => {
    try {
      if (res.data.success) {
        dispatch(loginSuccess(res.data.data.user));
        sessionStorage.setItem("user", JSON.stringify(res.data.data.user));
        sessionStorage.setItem("token", res.data.data.token);
      } else {
        toast({
          status: "error",
          description: res.data.error.message,
          isClosable: true,
          title: "Error",
          duration: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      return toast({
        title: "Error al iniciar sesión",
        status: "error",
        description: "Error de conexión al servidor",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getAccounts = async (ad) => {
    const res = await loginValidateAD(ad);

    handleLoginValidateADResult(res);
  };

  useEffect(() => {
    if (inProgress === "none" && isAutenticated && accounts.length > 0) {
      instance
        .acquireTokenSilent({
          scopes: [
            `api://${process.env.REACT_APP_AZURE_AD_CLIENT_ID}/User.Login`,
          ],
          account: accounts[0],
        })
        .then((resp) => getAccounts(resp));
    }
  }, [inProgress, isAutenticated, accounts]);

  return (
    <ChakraProvider theme={theme}>
      <Box width="100vw" height="100vh">
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routing />
          {/* <Alertas /> */}
        </BrowserRouter>
      </Box>
    </ChakraProvider>
  );
}

export default App;
