import React from "react";
import { MdOutlineCreate } from "react-icons/md";
import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";

import CrearEditarAsociacion from "./CrearEditarAsociacion";

const EditarAsociacion = (props) => {
  const { asociacion, initAsociaciones } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Tooltip label="Editar Asociación">
        <IconButton
          onClick={onOpen}
          icon={<MdOutlineCreate fontSize="20px" />}
          variant="link"
          _hover={{ color: "brand.naranja" }}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent
          bg="brand.fondos_secundarios"
          display="flex"
          flexDirection="column"
          height="100vh"
          width="100vw"
          maxW="none"
          maxH="none"
        >
          <ModalBody display="flex" flex="1" p={5} height="100%" width="100%">
            <CrearEditarAsociacion
              type="editar"
              asociacion={asociacion}
              initAsociaciones={initAsociaciones}
              onClose={onClose}
              style={{ height: "100%", width: "100%" }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarAsociacion;
