/* global google */
import React, { useRef, useState, useEffect } from "react";
import {
  Stack,
  HStack,
  Box,
  Text,
  Heading,
  Button,
  Image,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import pinCamionLleno from "../../assets/iconos/Camión lleno.png";
import pinCamionVacio from "../../assets/iconos/Camión vacío.png";
import pinPadIcon from "../../assets/iconos/PAD.png";
import pinArenaIcon from "../../assets/iconos/Planta.png";
import pinAlerta from "../../assets/iconos/Alerta.png";
import pinPuntoReferencia from "../../assets/iconos/Punto de referencia.png";
import Cerrar from "../../assets/iconos/cerrar.png";
import { getGeocercas } from "./helpers/api.helper";
import {
  getPlanificaciones,
  getPlantas,
} from "../operaciones/helpers/api.helper";
import moment from "moment";
import { ChevronDownIcon } from "@chakra-ui/icons";

const MapaCamiones = () => {
  const [map, setMap] = useState(null);
  const [active, setActive] = useState("todos");
  const [plantas, setPlantas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [operacion1, setOperacion1] = useState([]);
  const [operacion2, setOperacion2] = useState([]);
  const [tipo, setTipo] = useState();
  const infoWindowRef = useRef(null);
  const infoCamionRef = useRef(null);
  const [center, setCenter] = useState({
    lat: -38.33595844666532,
    lng: -68.79461933603682,
  });
  const [geocercas, setGeocercas] = useState([]);
  const mapRef = useRef();

  const initData = async () => {
    let res;

    res = await getPlantas();

    if (res.status === 200) {
      setPlantas(res.data);
    }

    res = await getGeocercas();

    if (res.status === 200) {
      setGeocercas(res.data);
    }

    res = await getPlanificaciones({ estado: "activo" });

    if (res.status === 200) {
      let resolve = res.data;

      let uno = resolve.find((e) => e.idSET === "set1");
      if (uno) {
        setOperacion1(uno);
      }
      let dos = resolve.find((e) => e.idSET === "set2");
      if (dos) {
        setOperacion2(dos);
      }
    }
    setLoading(false);
  };

  const renderMarkersForGeofences = (geofences, icon) => {
    if (geofences) {
      let elementos;
      if (icon === "PAD" || icon === "Planta") {
        elementos = geofences.filter((geofence) => geofence.tipoGeo === icon);
      } else {
        let x = geofences.filter((geofence) => geofence.tipoGeo !== "PAD");
        elementos = x.filter((geofence) => geofence.tipoGeo !== "Planta");
      }

      return elementos.map((geofence) => (
        <Marker
          key={geofence.idGeocerca}
          position={{
            lat: geofence.latitudCentro,
            lng: geofence.longitudCentro,
          }}
          map={map}
          onClick={() => handleInfoWindow(geofence)}
          icon={
            icon === "PAD"
              ? {
                  url: pinPadIcon, // URL del icono
                  scaledSize: new window.google.maps.Size(35, 50), // Tamaño del icono (ancho, alto)
                }
              : icon === "Planta"
              ? {
                  url: pinArenaIcon, // URL del icono
                  scaledSize: new window.google.maps.Size(35, 50), // Tamaño del icono (ancho, alto)
                }
              : {
                  url: pinPuntoReferencia, // URL del icono
                  scaledSize: new window.google.maps.Size(35, 50), // Tamaño del icono (ancho, alto)
                }
          }
          scale={1.5}
        />
      ));
    }
  };

  const handleInfoWindow = (geocerca) => {
    if (!infoWindowRef.current) {
      infoWindowRef.current = new google.maps.InfoWindow();
    } else {
      infoWindowRef.current.close();
    }

    const markerIconHeight = 50; // Altura del icono del marcador
    const infoWindowOffsetY = -markerIconHeight; // Desplazamiento hacia arriba

    const contentString = `
      <div style="display: flex; align-items: center;">
      <span>${geocerca.nombreGeo}</span>
      <button id="customCloseButton" style="margin-left: 10px; background: transparent; border: none; cursor: pointer;">
        <img src=${Cerrar} alt="Cerrar" style="width: 10px; height: 10px;">
      </button>
    </div>
     <style>
      .gm-style-iw button.gm-ui-hover-effect {
        display: none !important;
      }
    </style>
  `;

    infoWindowRef.current.setContent(contentString);
    infoWindowRef.current.setPosition({
      lat: geocerca.latitudCentro,
      lng: geocerca.longitudCentro,
    });

    const infoWindowPixelOffset = new google.maps.Size(0, infoWindowOffsetY);
    infoWindowRef.current.setOptions({ pixelOffset: infoWindowPixelOffset });

    infoWindowRef.current.open(map);

    google.maps.event.addListenerOnce(infoWindowRef.current, "domready", () => {
      document
        .getElementById("customCloseButton")
        .addEventListener("click", () => {
          infoWindowRef.current.close();
        });
    });
  };

  const handleInfoCamion = (camion) => {
    if (!infoCamionRef.current) {
      infoCamionRef.current = new google.maps.InfoWindow();
    }

    const markerIconHeight = 50; // Altura del icono del marcador
    const infoWindowOffsetY = -markerIconHeight; // Desplazamiento hacia arriba

    const contentString = `
    <div>
      <p>Int.: <strong>${camion.nombreCamion}</strong></p>
      <p>Dominio: <strong>${camion.dominio}</strong></p>
      <p>Última conexión: <strong>${moment
        .utc(camion.fechayhora)
        .format("DD/MM/YYYY HH:mm")}</strong></p>
    </div>
  `;

    infoCamionRef.current.setContent(contentString);
    infoCamionRef.current.setPosition({
      lat: camion.latUlitma,
      lng: camion.longUltima,
    });

    const infoWindowPixelOffset = new google.maps.Size(0, infoWindowOffsetY);
    infoCamionRef.current.setOptions({ pixelOffset: infoWindowPixelOffset });

    infoCamionRef.current.open(map);
  };

  const handleCamionesAmbas = () => {
    let tempAmbas = [];
    if (operacion1.camiones && operacion1.camiones.length > 0) {
      tempAmbas = [...tempAmbas, ...operacion1.camiones];
    } else if (operacion2.camiones && operacion2.camiones.length > 0) {
      tempAmbas = [...tempAmbas, ...operacion2.camiones];
    }

    return tempAmbas;
  };

  const showCamiones = () => {
    if (active === "todos") {
      return handleCamionesAmbas();
    } else if (
      active === "1" &&
      operacion1.camiones &&
      operacion1.camiones.length > 0
    ) {
      return operacion1.camiones.filter(
        (camion) => camion.camionActivo === "true"
      );
    } else if (
      active === "2" &&
      operacion2.camiones &&
      operacion2.camiones.length > 0
    ) {
      return operacion2.camiones.filter(
        (camion) => camion.camionActivo === "true"
      );
    } else {
      return [];
    }
  };

  const showPlantas = () => {
    if (active === "todos") {
      return geocercas;
    } else if (active === "1" && operacion1.detallePlantas) {
      const geocercasConMismoNombre = plantas.filter((e) => {
        return operacion1.detallePlantas.some(
          (planta) => planta.nombrePlanta === e.nombrePlanta
        );
      });
      const arrayFiltrado = [];
      geocercasConMismoNombre.forEach((objeto) => {
        if (objeto.geocerca) {
          const nombrePlanta = objeto.geocerca[0].nombreGeo;

          const geocercasFiltradas = geocercas.filter(
            (geocerca) => geocerca.nombreGeo === nombrePlanta
          );
          arrayFiltrado.push(geocercasFiltradas);
        }
      });
      let toMapear = arrayFiltrado.flat();

      return toMapear;
    } else if (active === "2" && operacion2.detallePlantas) {
      const geocercasConMismoNombre = plantas.filter((e) => {
        return operacion2.detallePlantas.some(
          (planta) => planta.nombrePlanta === e.nombrePlanta
        );
      });

      const arrayFiltrado = [];
      geocercasConMismoNombre.forEach((objeto) => {
        if (objeto.geocerca) {
          const nombrePlanta = objeto.geocerca[0].nombreGeo;
          const geocercasFiltradas = geocercas.filter(
            (geocerca) => geocerca.nombreGeo === nombrePlanta
          );
          arrayFiltrado.push(geocercasFiltradas);
        }
      });

      let toMapear = arrayFiltrado.flat();

      return toMapear;
    } else {
      return [];
    }
  };

  const showPAD = () => {
    if (active === "todos") {
      return geocercas;
    } else if (active === "1") {
      const set1 = geocercas.filter(
        (geocerca) => geocerca.nombreGeo === operacion1.idPadlocacion
      );

      return set1;
    } else if (active === "2") {
      const set2 = geocercas.filter(
        (geocerca) => geocerca.nombreGeo === operacion2.idPadlocacion
      );

      return set2;
    }
  };

  const showPuntosReferencia = () => {
    let ptosRef;
    ptosRef = geocercas.filter((e) => e.tipoGeo !== "PAD");
    ptosRef = ptosRef.filter((e) => e.tipoGeo !== "Planta");

    return ptosRef;
  };

  const showTipoSeleccionado = () => {
    if (tipo === "Planta") {
      return plantas;
    } else if (tipo === "PAD") {
      return geocercas.filter((e) => e.tipoGeo === "PAD");
    } else if (tipo === "Camion Lleno") {
      return handleCamionesAmbas().filter(
        (e) => e.reporteViaje?.status2RV === "EN TRÁNSITO A POZO"
      );
    } else if (tipo === "Camion Vacio") {
      return handleCamionesAmbas().filter(
        (e) => e.reporteViaje?.status2RV !== "EN TRÁNSITO A POZO"
      );
    } else if (tipo === "Punto referencia") {
      let x = geocercas.filter((e) => e.tipoGeo !== "Planta");
      return x.filter((e) => e.tipoGeo !== "PAD");
    }
  };

  const handleCenter = (elemento) => {
    if (tipo === "Planta") {
      setCenter({
        lat: elemento.geocerca[0].latitudCentro,
        lng: elemento.geocerca[0].longitudCentro,
      });
    } else if (tipo === "PAD") {
      setCenter({
        lat: elemento.latitudCentro,
        lng: elemento.longitudCentro,
      });
    } else if (tipo === "Camion Lleno") {
      setCenter({
        lat: elemento.latUlitma,
        lng: elemento.longUltima,
      });
    } else if (tipo === "Camion Vacio") {
      setCenter({
        lat: elemento.latUlitma,
        lng: elemento.longUltima,
      });
    } else if (tipo === "Punto referencia") {
      setCenter({
        lat: elemento.latitudCentro,
        lng: elemento.longitudCentro,
      });
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w={"100%"} h={"82%"} px={3}>
      <HStack>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Mapa de camiones
        </Heading>
      </HStack>
      <HStack
        bg={"white"}
        borderRadius={"10px"}
        justifyContent={"space-around"}
        minH={"70px"}
      >
        <HStack>
          <Box w={6} h={6}>
            <Image src={pinAlerta} alt="Alerta" />
          </Box>
          <Text>Alerta</Text>
        </HStack>
        <HStack>
          <Box w={6} h={6}>
            <Image src={pinPadIcon} alt="PAD" />
          </Box>
          <Text>PAD</Text>
        </HStack>
        <HStack>
          <Box w={6} h={6}>
            <Image src={pinArenaIcon} alt="Planta" />
          </Box>
          <Text>Planta</Text>
        </HStack>
        <HStack>
          <Box w={6} h={6}>
            <Image src={pinPuntoReferencia} alt="PuntoReferencia" />
          </Box>
          <Text>Pto. Referencia</Text>
        </HStack>
        <HStack>
          <Box w={6} h={6}>
            <Image src={pinCamionLleno} alt="Camion Lleno" />
          </Box>
          <Text>Camión lleno</Text>
        </HStack>
        <HStack>
          <Box w={6} h={6}>
            <Image src={pinCamionVacio} alt="Camion Vacio" />
          </Box>
          <Text>Camión vacio</Text>
        </HStack>
      </HStack>
      {!loading && (
        <HStack h={"100%"} minH={"85%"}>
          <Stack
            borderRadius={10}
            spacing={2}
            p={2}
            placeSelf={"start"}
            bg={"white"}
          >
            <Button
              variant={"ghost"}
              color={active === "todos" ? "white" : "brand.naranja"}
              bg={active === "todos" ? "brand.naranja" : "white"}
              _hover={{ bg: "brand.naranja", color: "white" }}
              onClick={() => setActive("todos")}
            >
              Todos
            </Button>
            <Button
              variant={"ghost"}
              color={active === "1" ? "white" : "brand.naranja"}
              bg={active === "1" ? "brand.naranja" : "white"}
              _hover={{ bg: "brand.naranja", color: "white" }}
              onClick={() => setActive("1")}
              isDisabled={operacion1.length === 0}
            >
              SET 1
            </Button>
            <Button
              variant={"ghost"}
              color={active === "2" ? "white" : "brand.naranja"}
              bg={active === "2" ? "brand.naranja" : "white"}
              _hover={{ bg: "brand.naranja", color: "white" }}
              onClick={() => setActive("2")}
              isDisabled={operacion2.length === 0}
            >
              SET 2
            </Button>
          </Stack>
          <Stack w={"75%"} h={"100%"} bg={"white"} placeSelf={"start"} p={2}>
            <Box h={"100%"} bg={"black"}>
              <GoogleMap
                zoom={17}
                center={center}
                mapTypeId={"satellite"}
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                }}
                map={mapRef}
                onLoad={(map) => setMap(map)}
              >
                {renderMarkersForGeofences(showPAD(), "PAD")}
                {renderMarkersForGeofences(showPuntosReferencia(), "PtoRef")}
                {renderMarkersForGeofences(showPlantas(), "Planta")}
                {showCamiones().map((camion) => (
                  <>
                    <Marker
                      key={camion.idGeoCamion}
                      position={{
                        lat: parseFloat(camion?.latUlitma),
                        lng: parseFloat(camion?.longUltima),
                      }}
                      icon={
                        camion.reporteViaje &&
                        camion.reporteViaje?.status2RV === "EN TRÁNSITO A POZO"
                          ? {
                              url: pinCamionLleno, // URL del icono
                              scaledSize: new window.google.maps.Size(40, 50), // Tamaño del icono (ancho, alto)
                            }
                          : {
                              url: pinCamionVacio, // URL del icono
                              scaledSize: new window.google.maps.Size(40, 50), // Tamaño del icono (ancho, alto)
                            }
                      }
                      scale={1.5}
                      map={map}
                      zIndex={100}
                      onClick={() => handleInfoCamion(camion)}
                    />
                  </>
                ))}
              </GoogleMap>
            </Box>
          </Stack>

          <Stack
            w={"25%"}
            borderRadius={10}
            p={2}
            bg={"white"}
            overflowY={"auto"}
            h={"100%"}
            placeSelf={"start"}
          >
            <Menu>
              <MenuButton
                as={Button}
                bg={"brand.azul_fondo"}
                rightIcon={<ChevronDownIcon />}
                color={"white"}
              >
                Seleccione un tipo
              </MenuButton>
              <MenuList
                textColor={"brand.azul_fondo"}
                border={"1px solid #004B87"}
              >
                <MenuItem
                  onClick={(e) => setTipo(e.target.value)}
                  value={"Planta"}
                >
                  Planta
                </MenuItem>
                <MenuItem
                  onClick={(e) => setTipo(e.target.value)}
                  value={"PAD"}
                >
                  PAD
                </MenuItem>
                <MenuItem
                  onClick={(e) => setTipo(e.target.value)}
                  value={"Camion Lleno"}
                >
                  Camión Lleno
                </MenuItem>
                <MenuItem
                  onClick={(e) => setTipo(e.target.value)}
                  value={"Camion Vacio"}
                >
                  Camión Vacio
                </MenuItem>
                <MenuItem
                  onClick={(e) => setTipo(e.target.value)}
                  value={"Punto referencia"}
                >
                  Pto. de Referencia
                </MenuItem>
              </MenuList>
            </Menu>
            <List h={"100%"} overflowY={"auto"}>
              {tipo &&
                showTipoSeleccionado().map((elemento, index) => (
                  <ListItem
                    key={index}
                    borderBottom={"1px solid #9EAAB8"}
                    cursor={"pointer"}
                    py={1}
                    px={1}
                    onClick={() => handleCenter(elemento)}
                    color={"brand.azul_fondo"}
                  >
                    {tipo === "Planta"
                      ? elemento.nombrePlanta
                      : tipo === "PAD"
                      ? elemento.nombreGeo
                      : tipo === "Camion Lleno"
                      ? elemento.dominio
                      : tipo === "Camion Vacio"
                      ? elemento.dominio
                      : tipo === "Punto referencia"
                      ? elemento.nombreGeo
                      : ""}
                  </ListItem>
                ))}
            </List>
          </Stack>
        </HStack>
      )}
    </Stack>
  );
};

export default MapaCamiones;
