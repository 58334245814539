import React, { useState, useEffect } from "react";
import {
  HStack,
  FormControl,
  Text,
  Stack,
  Button,
  Select,
  useToast,
  FormLabel,
  Input,
  Heading,
  Switch,
  Card,
  CardBody,
  CardFooter,
  Textarea,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import {
  MdNavigateNext,
  MdNavigateBefore,
  MdArrowDropDown,
  MdArrowBack,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import {
  crearRevisionGeneradores,
  editarRevisionGeneradores,
  getPlanificaciones,
  getRevisionGeneradores,
} from "../../../helpers/api.helper";

const RevisionGeneradores = (props) => {
  const { tipo } = props;
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [planificacion, setPlanificacion] = useState();
  const [responsable, setResponsable] = useState();
  const [fechaRevisionGNR, setFechaRevisionGNR] = useState();
  const [editable, setEditable] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const padLocacion = useParams();
  const { id } = useParams();
  const [observaciones, setObservaciones] = useState("");
  const charCount = observaciones.length;

  const initUsarios = async () => {
    let res;

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    }

    res = await getPlanificaciones(padLocacion);

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    }

    res = await getRevisionGeneradores();

    if (res.status === 200) {
      if (tipo === "crear") {
        setEditable(res.data);
      }

      if (tipo === "editar") {
        setEditable(() => {
          let a = res.data.filter(
            (e) => e.idRevisionGenerador === parseInt(id)
          );

          return a[0];
        });
      }

      setLoading(false);
    }
  };
  function formatearHorarioActual(e) {
    let year, month, day, hours, minutes;

    year = new Date(e).getFullYear();
    month = String(new Date(e).getMonth() + 1).padStart(2, "0");
    day = String(new Date(e).getDate()).padStart(2, "0");
    hours = String(new Date(e).getUTCHours()).padStart(2, "0");
    minutes = String(new Date(e).getMinutes()).slice(-2).padStart(2, "0");

    return (
      year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":00.000"
    );
  }

  useEffect(() => {
    initUsarios();
  }, []);

  let initialValues =
    tipo === "editar"
      ? {
          nivelRefrigeranteRG: editable.nivelRefrigeranteRG,
          nivelAceiteRG: editable.nivelAceiteRG,
          nivelCombustibleRG: editable.nivelCombustibleRG,
          filtroAireRG: editable.filtroAireRG,
          horometroRG: editable.horometroRG,
          voltajeRG: editable.voltajeRG,
          frecuenciaRG: editable.frecuenciaRG,
          presionAceiteRG: editable.presionAceiteRG,
          temperaturaRG: editable.temperaturaRG,
          fallaDisplayRG: editable.fallaDisplayRG,
          correaRG: editable.correaRG,
          manguerasRG: editable.manguerasRG,
          cargadorBateriasRG: editable.cargadorBateriasRG,
          bornesRG: editable.bornesRG,
          disyuntorRG: editable.disyuntorRG,
          observacionesRG: editable.observacionesRG,
          responsable: editable.responsable,
        }
      : {
          nivelRefrigeranteRG: false,
          nivelAceiteRG: false,
          nivelCombustibleRG: false,
          filtroAireRG: false,
          horometroRG: false,
          voltajeRG: false,
          frecuenciaRG: false,
          presionAceiteRG: false,
          temperaturaRG: false,
          fallaDisplayRG: false,
          correaRG: false,
          manguerasRG: false,
          cargadorBateriasRG: false,
          bornesRG: false,
          disyuntorRG: false,
          observacionesRG: "",
          responsable: "",
        };

  return (
    <Stack w="100%">
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading px={1} size={"sm"}>
          Revision Generador
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            let data =
              tipo === "editar"
                ? {
                    responsable: responsable,
                    id: editable.idRevisionGenerador,
                    idPlanificacion: editable.idPlanificacion,
                    nivelRefrigeranteRG: values.nivelRefrigeranteRG,
                    nivelAceiteRG: values.nivelAceiteRG,
                    nivelCombustibleRG: values.nivelCombustibleRG,
                    filtroAireRG: values.filtroAireRG,
                    horometroRG: values.horometroRG,
                    voltajeRG: values.voltajeRG,
                    frecuenciaRG: values.frecuenciaRG,
                    presionAceiteRG: values.presionAceiteRG,
                    temperaturaRG: values.temperaturaRG,
                    fallaDisplayRG: values.fallaDisplayRG,
                    correaRG: values.correaRG,
                    manguerasRG: values.manguerasRG,
                    cargadorBateriasRG: values.cargadorBateriasRG,
                    bornesRG: values.bornesRG,
                    disyuntorRG: values.disyuntorRG,
                    equipoRevision: "Generador",

                    observacionesRG: observaciones,
                  }
                : {
                    responsable: responsable,
                    idPlanificacion: planificacion.idPlanificacion,
                    nivelRefrigeranteRG: values.nivelRefrigeranteRG,
                    nivelAceiteRG: values.nivelAceiteRG,
                    nivelCombustibleRG: values.nivelCombustibleRG,
                    filtroAireRG: values.filtroAireRG,
                    horometroRG: values.horometroRG,
                    voltajeRG: values.voltajeRG,
                    frecuenciaRG: values.frecuenciaRG,
                    presionAceiteRG: values.presionAceiteRG,
                    temperaturaRG: values.temperaturaRG,
                    fallaDisplayRG: values.fallaDisplayRG,
                    correaRG: values.correaRG,
                    manguerasRG: values.manguerasRG,
                    cargadorBateriasRG: values.cargadorBateriasRG,
                    bornesRG: values.bornesRG,
                    disyuntorRG: values.disyuntorRG,
                    equipoRevision: "Generador",

                    observacionesRG: observaciones,
                  };

            let res;
            if (tipo === "editar") {
              res = await editarRevisionGeneradores(data);
            } else {
              res = await crearRevisionGeneradores(data);
            }
            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de generador ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
            } else if (res.status === 204) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de generador ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
              toast({
                status: "warning",
                isClosable: true,
                title: `No hay usuarios activos para notificar`,
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  tipo === "editar" ? "editar" : "crear"
                } revision de generador`,
                duration: 3000,
              });
            }

            navigate(-1);
            setLoading(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form id="generador">
              <Stack w="100%" spacing={5}>
                <HStack px={5}>
                  <FormControl isRequired>
                    <FormLabel>Responsable</FormLabel>
                    <Select
                      w="2xs"
                      size="sm"
                      placeholder="Seleccione responsable"
                      icon={<MdArrowDropDown />}
                      onChange={(e) => setResponsable(e.target.value)}
                    >
                      {usuarios
                        .sort((a, b) => {
                          // Ordenar alfabéticamente por nombre y apellido
                          const nameA = `${a.nombre}`.toUpperCase();
                          const nameB = `${b.nombre}`.toUpperCase();
                          if (nameA < nameB) return -1;
                          if (nameA > nameB) return 1;
                          return 0;
                        })
                        .map((usuario) => (
                          <option
                            key={usuario.idUsuario}
                            value={usuario.idUsuario}
                          >
                            {usuario.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Fecha y Hora</FormLabel>
                    <Input
                      w="2xs"
                      size="sm"
                      type="datetime-local"
                      onChange={(e) => setFechaRevisionGNR(e.target.value)}
                    />
                  </FormControl>
                </HStack>
                <HStack justifyContent="space-around" pb={3}>
                  <FormControl></FormControl>
                  <FormControl>
                    <Text fontWeight="bold">Parametros de funcionamiento</Text>
                  </FormControl>
                  <FormControl></FormControl>
                </HStack>
                <HStack justifyContent="space-around" pb={3}>
                  <Stack w="xs">
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="nivelRefrigeranteRG"
                        isChecked={values.nivelRefrigeranteRG}
                      />
                      <Text>Nivel de Refrigerante</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="nivelAceiteRG"
                        isChecked={values.nivelAceiteRG}
                      />
                      <Text>Nivel de Aceite de motor</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="nivelCombustibleRG"
                        isChecked={values.nivelCombustibleRG}
                      />
                      <Text>Nivel de combustible</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="filtroAireRG"
                        isChecked={values.filtroAireRG}
                      />
                      <Text>Condicion de filtro de aire</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="fallaDisplayRG"
                        isChecked={values.fallaDisplayRG}
                      />
                      <Text>Display muestra fallas</Text>
                    </HStack>
                  </Stack>
                  <Stack w="xs">
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="horometroRG"
                        isChecked={values.horometroRG}
                      />
                      <Text>Horometro</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="voltajeRG"
                        isChecked={values.voltajeRG}
                      />
                      <Text>Voltaje</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="frecuenciaRG"
                        isChecked={values.frecuenciaRG}
                      />
                      <Text>Frecuencia</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="presionAceiteRG"
                        isChecked={values.presionAceiteRG}
                      />
                      <Text>Presión de aceite</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="temperaturaRG"
                        isChecked={values.temperaturaRG}
                      />
                      <Text>Temperatura de motor</Text>
                    </HStack>
                  </Stack>
                  <Stack w="xs">
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="correaRG"
                        isChecked={values.correaRG}
                      />
                      <Text>Condición de la correa</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="manguerasRG"
                        isChecked={values.manguerasRG}
                      />
                      <Text>Perdidas en Manguera</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="cargadorBateriasRG"
                        isChecked={values.cargadorBateriasRG}
                      />
                      <Text>Cargador de baterías (si aplica)</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="bornesRG"
                        isChecked={values.bornesRG}
                      />
                      <Text>Observa bornes sulfatados/flojos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="disyuntorRG"
                        isChecked={values.disyuntorRG}
                      />
                      <Text>Prueba de disyuntor</Text>
                    </HStack>
                  </Stack>
                </HStack>
                <HStack w="100%" justifyContent="space-between" p={3}>
                  <Box></Box>
                  <FormControl w={"lg"}>
                    <FormLabel>Observaciones</FormLabel>
                    <Card
                      w="lg"
                      variant="unstyled"
                      bg="brand.fondos_secundarios"
                    >
                      <CardBody>
                        <Textarea
                          w="lg"
                          placeholder="Descripcion"
                          name="observacionesRG"
                          onChange={(e) => setObservaciones(e.target.value)}
                          onBlur={handleBlur}
                          maxLength="1500"
                          resize="none"
                        />
                      </CardBody>
                      <CardFooter placeSelf="end" color="brand.gris_medio">
                        {charCount}/1500
                      </CardFooter>
                    </Card>
                  </FormControl>
                  <Button
                    placeSelf={"end"}
                    fontSize={12}
                    fontWeight="semibold"
                    bg="brand.naranja"
                    color="white"
                    rightIcon={<MdNavigateNext fontSize="20px" />}
                    variant="solid"
                    form="generador"
                    isDisabled={loading}
                    onClick={handleSubmit}
                    _hover={{ bg: "white", color: "brand.naranja" }}
                  >
                    Finalizar
                  </Button>
                </HStack>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default RevisionGeneradores;
