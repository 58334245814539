import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  IconButton,
  List,
  ListItem,
} from "@chakra-ui/react";
import {
  MdOutlineImportExport,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { sortData } from "../../../utils/sortData";
import moment from "moment/moment";
import AgregarNroremito from "../../operaciones/components/curso/reporte/AgregarNroremito";
import AgregarRemito from "../../operaciones/components/curso/reporte/AgregarRemito";
import AgregarToneladas from "../../operaciones/components/curso/reporte/AgregarToneladas";
import { FaCircleInfo } from "react-icons/fa6";

const DisenoTabla = (props) => {
  const {
    user,
    initData,
    reportesViajes,
    setReportesViajes,
    handleCierreManual,
    camiones,
    isOpen,
    setIsOpen,
    fechaFin,
    setFechaFin,
    camionFin,
    setCamionFin,
    filtroCamiones,
    setFiltroCamiones,
    onClose,
  } = props;
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [editable, setEditable] = useState();

  const [dragging, setDragging] = useState(false);
  const [draggedX, setDraggedX] = useState(0);
  const [tableX, setTableX] = useState(0);
  const [showIcon, setShowIcon] = useState({
    display: false,
    element: "",
  });

  const tableRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    if (tableRef.current) {
      const savedScrollLeft = localStorage.getItem("scrollLeft");
      if (savedScrollLeft !== null) {
        tableRef.current.scrollLeft = parseInt(savedScrollLeft);
      }
    }
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      localStorage.setItem("scrollLeft", tableRef.current.scrollLeft);
    }
  }, [tableRef.current?.scrollLeft]);

  const reloadTableData = async () => {
    // Guarda la posición actual del scroll horizontal
    const currentScrollLeft = tableRef.current
      ? tableRef.current.scrollLeft
      : 0;

    // Lógica para recargar los datos de la tabla...

    // Una vez que se completa la recarga, restaura la posición del scroll horizontal
    setScrollLeft(currentScrollLeft);
  };

  const onOpen = () => setIsOpen(true);
  const handleColorEstado = (e) => {
    switch (e) {
      case "Finalizado":
        return "brand.verde";
      case "Caducado":
        return "brand.rojo";
      case "En curso":
        return "#fbb034";
      case "cancelado":
        return "brand.gris_medio";
      default:
        return "-";
    }
  };

  const handleTextoEstado = (e) => {
    switch (e) {
      case "En curso":
        return "En curso";
      case "Finalizado":
        return "Finalizado";
      case "cancelado":
        return "Cancelado";
      case "Caducado":
        return "Caducado";
      default:
        return "-";
    }
  };

  const handleDobleClick = (reporte) => {
    if (reporte) {
      setEditable(reporte);
    }
    onOpen();
  };

  const handleSorteable = (element, nombre) => {
    return (
      <Th
        _hover={{ cursor: "pointer" }}
        onMouseEnter={() => setShowIcon({ display: true, element: element })}
        onMouseLeave={() => setShowIcon({ display: false, element: "" })}
      >
        <span
          className="sortable"
          onClick={() => {
            const { sortedData, direction } = sortData(element, reportesViajes);
            setSortField(element);
            setSortDirection(direction);
            setReportesViajes(sortedData);
          }}
        >
          {nombre}
          {sortDirection === "asc" && sortField === element && <ArrowUpIcon />}
          {sortDirection === "des" && sortField === element && (
            <ArrowDownIcon />
          )}
        </span>
        {showIcon && element === showIcon.element && (
          <span style={{ display: "inline-block" }}>
            <MdOutlineImportExport />
          </span>
        )}
      </Th>
    );
  };

  return (
    <>
      <TableContainer h={"calc(100vh - 130px)"} overflowY={"scroll"} id="TBody">
        <Table size="sm" variant="simple" ref={tableRef}>
          <Thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "#F3F6F9",
              opacity: 1,
            }}
          >
            <Tr>
              {handleSorteable("idReporteViaje", "ID viaje")}
              {handleSorteable("statusRV", "Estado")}
              {handleSorteable("status2RV", "Locación")}
              {handleSorteable("padDestino", "PAD destino")}
              {handleSorteable("transporteRV", "Transporte")}
              {handleSorteable("camionRV", "Camion")}
              {handleSorteable("nombreCamion", "Interno")}
              <Th>Camion fin</Th>
              {handleSorteable("sandCube1", "SCs asociados")}
              {handleSorteable("sandCube2", "SCs asociados")}
              {handleSorteable("plantaCarga", "Planta Carga")}
              {handleSorteable("tsPozoOut", "TS Pozo out")}
              {handleSorteable("tsPlantaIn", "TS Planta in")}
              {handleSorteable("tsPlantaOut", "TS Planta out")}
              {handleSorteable("tsPozoIn", "TS Pozo in")}
              {handleSorteable("eta", "ETA")}
              {handleSorteable("standByPlanta", "Stand en Planta (mins.)")}
              {handleSorteable("standByPozo", "Standby en Pozo (mins.)")}
              {handleSorteable("tiempoVuelta", "Tiempo Vuelta (mins.)")}
              {handleSorteable("kms", "KMS")}
              {handleSorteable("fechaRV", "Fecha reporte")}
              {handleSorteable("cargaTotal", "Carga Total")}
              {handleSorteable("demoras", "Demoras (mins.)")}
              {handleSorteable("numRemitoRV", "Nro. de Remito")}
              <Th>Imagen Remito</Th>
              {handleSorteable("ToneladasRV", "Toneladas")}
            </Tr>
          </Thead>

          <Tbody>
            {reportesViajes.map((reporte, index) => (
              <Tr key={reporte.idReporteViaje}>
                <Td textAlign={"right"}>{reporte.idReporteViaje}</Td>
                <Td color={handleColorEstado(reporte.statusRV)}>
                  {handleTextoEstado(reporte.statusRV)}
                </Td>
                <Td>{reporte.status2RV}</Td>
                <Td>{reporte.padDestino}</Td>
                <Td>{reporte.transporteRV}</Td>
                <Td textAlign={"left"}>{reporte.camionRV}</Td>
                <Td textAlign={"left"}>{reporte.nombreCamion}</Td>
                <Td textAlign={"center"}>
                  {reporte.camionFinal ? (
                    reporte.camionFinal
                  ) : (user.idRol === "coorOp" || user.idRol === "admin") &&
                    reporte.statusRV === "En curso" &&
                    reporte.tsPlantaOut &&
                    moment().diff(moment(reporte.tsPlantaOut), "hours") > 8 &&
                    !reporte.tsPozoIn ? (
                    <IconButton
                      onClick={() => handleDobleClick(reporte)}
                      icon={<FaCircleInfo />}
                      variant={"link"}
                      color={"brand.naranja"}
                    />
                  ) : (
                    "--"
                  )}
                </Td>

                <Td>{reporte.sandCube1}</Td>
                <Td>{reporte.sandCube2}</Td>
                <Td>{reporte.plantaCarga}</Td>
                <Td>
                  {reporte.tsPozoOut &&
                    moment.utc(reporte.tsPozoOut).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.tsPlantaIn &&
                    moment.utc(reporte.tsPlantaIn).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.tsPlantaOut &&
                    moment.utc(reporte.tsPlantaOut).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.tsPozoIn &&
                    moment.utc(reporte.tsPozoIn).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.eta &&
                    moment.utc(reporte.eta).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td textAlign={"right"}>{reporte.standByPlanta}</Td>
                <Td textAlign={"right"}>{reporte.standByPozo}</Td>
                <Td textAlign={"right"}>{reporte.tiempoVuelta}</Td>
                <Td textAlign={"right"}>{reporte.kms}</Td>
                <Td>
                  {reporte.tsPozoIn
                    ? moment.utc(reporte.tsPozoIn).format("DD/MM/YYYY HH:mm")
                    : moment.utc(reporte.fechaRV).format("DD/MM/YYYY")}
                </Td>
                <Td textAlign={"right"}>{reporte.cargaTotal}</Td>
                <Td textAlign={"right"}>{reporte.demoras}</Td>
                {!reporte.numRemitoRV &&
                (user.idRol === "admin" || user.idRol === "coorOp") ? (
                  <Td>
                    <AgregarNroremito
                      reporte={reporte}
                      initData={initData}
                      reportesViajes={reportesViajes}
                      setReportesViajes={setReportesViajes}
                    />
                  </Td>
                ) : (
                  <Td>{reporte.numRemitoRV || "-"}</Td>
                )}
                {!reporte.imgRemitoRV &&
                (user.idRol === "admin" || user.idRol === "coorOp") ? (
                  <Td>
                    <AgregarRemito
                      reporte={reporte}
                      initData={initData}
                      reportesViajes={reportesViajes}
                      setReportesViajes={setReportesViajes}
                    />
                  </Td>
                ) : reporte.imgRemitoRV ? (
                  <Td>
                    <Icon
                      as={MdOutlineCheckCircleOutline}
                      color="white"
                      bg="green"
                      borderRadius="50%"
                    />
                  </Td>
                ) : (
                  <Td>-</Td>
                )}
                {!reporte.ToneladasRV &&
                (user.idRol === "admin" || user.idRol === "coorOp") ? (
                  <Td>
                    <AgregarToneladas
                      reporte={reporte}
                      initData={initData}
                      reportesViajes={reportesViajes}
                      setReportesViajes={setReportesViajes}
                    />
                  </Td>
                ) : (
                  <Td textAlign={"right"}>{reporte.ToneladasRV || "-"}</Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {editable && (
        <Modal isOpen={isOpen} onClose={onClose} size={"lg"} isLazy>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Reporte N° {editable.idReporteViaje}</ModalHeader>
            <ModalCloseButton onClick={onClose} />
            <ModalBody border={"1px solid gray"} borderRadius={"10px"} mx={3}>
              <Stack>
                <FormControl>
                  <HStack justifyContent={"space-between"}>
                    <FormLabel>Camion inicio del viaje</FormLabel>
                    <Text>{editable.camionRV}</Text>
                  </HStack>
                </FormControl>
                <FormControl isRequired>
                  <HStack justifyContent={"space-between"}>
                    <FormLabel>Camion fin del viaje</FormLabel>
                    <Stack>
                      <Input
                        w="xs"
                        variant="flushed"
                        type="text"
                        placeholder="Camion"
                        style={{ fontWeight: "bold", color: "black" }}
                        onChange={(e) => {
                          const value = e.target.value ?? "";
                          setFiltroCamiones(value.toLowerCase());
                        }}
                        value={camionFin?.dominio}
                      />
                      {filtroCamiones && (
                        <List
                          w={"2xs"}
                          position={"absolute"}
                          top={39}
                          bg={"white"}
                          h={40}
                          overflowY={"scroll"}
                          zIndex={10}
                          cursor={"pointer"}
                        >
                          {camiones
                            .filter((element) =>
                              element.dominio
                                .toLowerCase()
                                .includes(filtroCamiones)
                            )
                            .map((element) => (
                              <ListItem
                                w={"100%"}
                                onClick={() => {
                                  setCamionFin(element);
                                  setFiltroCamiones();
                                }}
                              >
                                {element.dominio}
                              </ListItem>
                            ))}
                        </List>
                      )}
                    </Stack>
                  </HStack>
                </FormControl>
                <FormControl isRequired>
                  <HStack justifyContent={"space-between"}>
                    <FormLabel>Hora fin del viaje</FormLabel>
                    <Input
                      w={"2xs"}
                      type="datetime-local"
                      min={editable.tsPozoOut}
                      onChange={(e) => setFechaFin(e.target.value)}
                    />
                  </HStack>
                </FormControl>
                <FormControl>
                  <HStack justifyContent={"space-between"}>
                    <FormLabel>Estado</FormLabel>
                    <HStack>
                      <Text color={handleColorEstado("En curso")}>
                        {handleTextoEstado("En curso")}
                      </Text>
                      <Text fontWeight={"bold"}>{">>"}</Text>
                      <Text color={handleColorEstado("Finalizado")}>
                        {handleTextoEstado("Finalizado")}
                      </Text>
                    </HStack>
                  </HStack>
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                colorScheme="red"
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                bg={"brand.naranja"}
                color={"white"}
                onClick={() =>
                  handleCierreManual(editable, fechaFin, camionFin)
                }
              >
                Finalizar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default DisenoTabla;
