import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  Stack,
  VStack,
  HStack,
  FormControl,
  Button,
  Select,
  useToast,
  FormLabel,
  Input,
  Heading,
  Text,
  Switch,
  Card,
  CardBody,
  CardFooter,
  Textarea,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { MdNavigateNext, MdArrowDropDown, MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import {
  crearRevisionAutoelevadores,
  editarRevisionAutoelevadores,
  getPlanificaciones,
  getRevisionAutoelevadores,
} from "../../../helpers/api.helper";

const RevisionAutoelevador = (props) => {
  const { tipo, actualizarNotificaciones } = props;
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [planificacion, setPlanificacion] = useState();
  const [codigoAutoE, setCodigoAutoE] = useState();
  const [fechaRevisionAutoE, setFechaRevisionAutoE] = useState();
  const [responsable, setResponsable] = useState();
  const [editable, setEditable] = useState([]);
  const [observaciones, setObservaciones] = useState("");
  const charCount = observaciones.length;
  const padLocacion = useParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const initUsarios = async () => {
    let res;

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    }

    res = await getPlanificaciones(padLocacion);

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    }

    res = await getRevisionAutoelevadores();
    if (res.status === 200) {
      if (tipo === "crear") {
        setEditable(res.data);
      }
      if (tipo === "editar") {
        setEditable(() => {
          let a = res.data.filter((e) => e.idRevisionAutoE === parseInt(id));
          return a[0];
        });
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    initUsarios();
  }, []);

  let initialValues =
    tipo === "editar"
      ? {
          documentacionAutoE: editable.documentacionAutoE,
          cabinaAutoE: editable.cabinaAutoE,
          limpiaparabrisasAutoE: editable.limpiaparabrisasAutoE,
          lucesAutoE: editable.lucesAutoE,
          asientoAutoE: editable.asientoAutoE,
          cinturonSeguridadAutoE: editable.cinturonSeguridadAutoE,
          aireAcondicionadoAutoE: editable.aireAcondicionadoAutoE,
          alarmasAutoE: editable.alarmasAutoE,
          bocinaAutoE: editable.bocinaAutoE,
          instrumentalAutoE: editable.instrumentalAutoE,
          estructuraAutoE: editable.estructuraAutoE,
          engraseAutoE: editable.engraseAutoE,
          proteccionesAutoE: editable.proteccionesAutoE,
          sistemaElectricoAutoE: editable.sistemaElectricoAutoE,
          motorAutoE: editable.motorAutoE,
          nivelAutoE: editable.nivelAutoE,
          sistemaHidraulicoAutoE: editable.sistemaHidraulicoAutoE,
          transmisionAutoE: editable.transmisionAutoE,
          tapaAutoE: editable.tapaAutoE,
          diferencialAutoE: editable.diferencialAutoE,
          sistemaDireccionAutoE: editable.sistemaDireccionAutoE,
          bloqueosAutoE: editable.bloqueosAutoE,
          finCarreraAutoE: editable.finCarreraAutoE,
          limitadorAutoE: editable.limitadorAutoE,
          ajusteTuercasAutoE: editable.ajusteTuercasAutoE,
          pernosAutoE: editable.pernosAutoE,
          alemitesAutoE: editable.alemitesAutoE,
          cilindrosHidraulicosAutoE: editable.cilindrosHidraulicosAutoE,
          manguerasAutoE: editable.manguerasAutoE,
          valvulasAutoE: editable.valvulasAutoE,
          frenosAutoE: editable.frenosAutoE,
          ruedasAutoE: editable.ruedasAutoE,
          urrAutoE: editable.urrAutoE,
          perdidasFluidoAutoE: editable.perdidasFluidoAutoE,
          extintorAutoE: editable.extintorAutoE,
          responsable: editable.responsable,
        }
      : {
          documentacionAutoE: false,
          cabinaAutoE: false,
          limpiaparabrisasAutoE: false,
          lucesAutoE: false,
          asientoAutoE: false,
          cinturonSeguridadAutoE: false,
          aireAcondicionadoAutoE: false,
          alarmasAutoE: false,
          bocinaAutoE: false,
          instrumentalAutoE: false,
          estructuraAutoE: false,
          engraseAutoE: false,
          proteccionesAutoE: false,
          sistemaElectricoAutoE: false,
          motorAutoE: false,
          nivelAutoE: false,
          sistemaHidraulicoAutoE: false,
          transmisionAutoE: false,
          tapaAutoE: false,
          diferencialAutoE: false,
          sistemaDireccionAutoE: false,
          bloqueosAutoE: false,
          finCarreraAutoE: false,
          limitadorAutoE: false,
          ajusteTuercasAutoE: false,
          pernosAutoE: false,
          alemitesAutoE: false,
          cilindrosHidraulicosAutoE: false,
          manguerasAutoE: false,
          valvulasAutoE: false,
          frenosAutoE: false,
          ruedasAutoE: false,
          urrAutoE: false,
          perdidasFluidoAutoE: false,
          extintorAutoE: false,
          responsable: "",
        };

  return (
    <Stack w="100%">
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading size={"sm"}>Revision Autoelevador</Heading>
      </HStack>
      {!loading && (
        <Stack w="100%" p={5}>
          <HStack px={7}>
            <FormControl isRequired>
              <FormLabel>Responsable</FormLabel>
              <Select
                w="2xs"
                size="sm"
                placeholder="Seleccione responsable"
                icon={<MdArrowDropDown />}
                onChange={(e) => setResponsable(e.target.value)}
              >
                {usuarios
                  .sort((a, b) => {
                    // Ordenar alfabéticamente por nombre y apellido
                    const nameA = `${a.nombre}`.toUpperCase();
                    const nameB = `${b.nombre}`.toUpperCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  })
                  .map((usuario) => (
                    <option key={usuario.idUsuario} value={usuario.idUsuario}>
                      {usuario.nombre}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Autoelevador</FormLabel>
              {tipo === "crear" && (
                <Select
                  w="2xs"
                  size="sm"
                  placeholder="Seleccione autoelevador"
                  icon={<MdArrowDropDown />}
                  onChange={(e) => setCodigoAutoE(e.target.value)}
                >
                  <option value={planificacion.autoelevador1.idAutoE}>
                    {planificacion.autoelevador1.nombreAutoE}
                  </option>
                  <option value={planificacion.autoelevador2.idAutoE}>
                    {planificacion.autoelevador2.nombreAutoE}
                  </option>
                </Select>
              )}
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Fecha y Hora</FormLabel>
              <Input
                w="2xs"
                size="sm"
                type="datetime-local"
                onChange={(e) => setFechaRevisionAutoE(e.target.value)}
              />
            </FormControl>
          </HStack>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              setLoading(true);
              let data =
                tipo === "editar"
                  ? {
                      responsable: responsable,
                      id: editable.idRevisionAutoE,
                      idPlanificacion: editable.idPlanificacion,
                      documentacionAutoE: values.documentacionAutoE,
                      cabinaAutoE: values.cabinaAutoE,
                      limpiaparabrisasAutoE: values.limpiaparabrisasAutoE,
                      lucesAutoE: values.lucesAutoE,
                      asientoAutoE: values.asientoAutoE,
                      cinturonSeguridadAutoE: values.cinturonSeguridadAutoE,
                      aireAcondicionadoAutoE: values.aireAcondicionadoAutoE,
                      alarmasAutoE: values.alarmasAutoE,
                      bocinaAutoE: values.bocinaAutoE,
                      instrumentalAutoE: values.instrumentalAutoE,
                      estructuraAutoE: values.estructuraAutoE,
                      engraseAutoE: values.engraseAutoE,
                      proteccionesAutoE: values.proteccionesAutoE,
                      sistemaElectricoAutoE: values.sistemaElectricoAutoE,
                      motorAutoE: values.motorAutoE,
                      nivelAutoE: values.nivelAutoE,
                      sistemaHidraulicoAutoE: values.sistemaHidraulicoAutoE,
                      transmisionAutoE: values.transmisionAutoE,
                      tapaAutoE: values.tapaAutoE,
                      diferencialAutoE: values.diferencialAutoE,
                      sistemaDireccionAutoE: values.sistemaDireccionAutoE,
                      bloqueosAutoE: values.bloqueosAutoE,
                      finCarreraAutoE: values.finCarreraAutoE,
                      limitadorAutoE: values.limitadorAutoE,
                      ajusteTuercasAutoE: values.ajusteTuercasAutoE,
                      pernosAutoE: values.pernosAutoE,
                      alemitesAutoE: values.alemitesAutoE,
                      cilindrosHidraulicosAutoE:
                        values.cilindrosHidraulicosAutoE,
                      manguerasAutoE: values.manguerasAutoE,
                      valvulasAutoE: values.valvulasAutoE,
                      frenosAutoE: values.frenosAutoE,
                      ruedasAutoE: values.ruedasAutoE,
                      urrAutoE: values.urrAutoE,
                      perdidasFluidoAutoE: values.perdidasFluidoAutoE,
                      extintorAutoE: values.extintorAutoE,
                      tipoAutoE: editable.tipoAutoE,
                      equipoRevision: "Autoelevador",
                    }
                  : {
                      responsable: responsable,
                      idPlanificacion: planificacion.idPlanificacion,
                      documentacionAutoE: values.documentacionAutoE,
                      cabinaAutoE: values.cabinaAutoE,
                      limpiaparabrisasAutoE: values.limpiaparabrisasAutoE,
                      lucesAutoE: values.lucesAutoE,
                      asientoAutoE: values.asientoAutoE,
                      cinturonSeguridadAutoE: values.cinturonSeguridadAutoE,
                      aireAcondicionadoAutoE: values.aireAcondicionadoAutoE,
                      alarmasAutoE: values.alarmasAutoE,
                      bocinaAutoE: values.bocinaAutoE,
                      instrumentalAutoE: values.instrumentalAutoE,
                      estructuraAutoE: values.estructuraAutoE,
                      engraseAutoE: values.engraseAutoE,
                      proteccionesAutoE: values.proteccionesAutoE,
                      sistemaElectricoAutoE: values.sistemaElectricoAutoE,
                      motorAutoE: values.motorAutoE,
                      nivelAutoE: values.nivelAutoE,
                      sistemaHidraulicoAutoE: values.sistemaHidraulicoAutoE,
                      transmisionAutoE: values.transmisionAutoE,
                      tapaAutoE: values.tapaAutoE,
                      diferencialAutoE: values.diferencialAutoE,
                      sistemaDireccionAutoE: values.sistemaDireccionAutoE,
                      bloqueosAutoE: values.bloqueosAutoE,
                      finCarreraAutoE: values.finCarreraAutoE,
                      limitadorAutoE: values.limitadorAutoE,
                      ajusteTuercasAutoE: values.ajusteTuercasAutoE,
                      pernosAutoE: values.pernosAutoE,
                      alemitesAutoE: values.alemitesAutoE,
                      cilindrosHidraulicosAutoE:
                        values.cilindrosHidraulicosAutoE,
                      manguerasAutoE: values.manguerasAutoE,
                      valvulasAutoE: values.valvulasAutoE,
                      frenosAutoE: values.frenosAutoE,
                      ruedasAutoE: values.ruedasAutoE,
                      urrAutoE: values.urrAutoE,
                      perdidasFluidoAutoE: values.perdidasFluidoAutoE,
                      extintorAutoE: values.extintorAutoE,
                      tipoAutoE: codigoAutoE,
                      equipoRevision: "Autoelevador",
                    };

              let res;
              if (tipo === "editar") {
                res = await editarRevisionAutoelevadores(data);
              } else {
                res = await crearRevisionAutoelevadores(data);
              }
              if (res.status === 200) {
                toast({
                  status: "success",
                  isClosable: true,
                  title: `Revision de autoelevador ${
                    tipo === "editar" ? "editada" : "creada"
                  } correctamente`,
                  duration: 3000,
                });
              } else if (res.status === 204) {
                toast({
                  status: "success",
                  isClosable: true,
                  title: `Revision de autoelevador ${
                    tipo === "editar" ? "editada" : "creada"
                  } correctamente`,
                  duration: 3000,
                });
                toast({
                  status: "warning",
                  isClosable: true,
                  title: `No hay usuarios activos para notificar`,
                  duration: 3000,
                });
              } else {
                setLoading(false);
                return toast({
                  status: "error",
                  isClosable: true,
                  title: `Error al ${
                    tipo === "editar" ? "editar" : "crear"
                  } revision de autoelevador`,
                  duration: 3000,
                });
              }

              navigate(-1);
              setLoading(false);
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form id="autoelevador1">
                <HStack justifyContent="space-between">
                  <Stack placeSelf="start" pr={2}>
                    <HStack>
                      <Switch
                        name="documentacionAutoE"
                        isChecked={values.documentacionAutoE}
                        onChange={handleChange}
                      />
                      <Text>Documentos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.cabinaAutoE}
                        name="cabinaAutoE"
                      />
                      <Text>
                        Cabina, Vidrio, Jaula <br /> (interio, exterior) <br />{" "}
                        barandas y peldaños
                      </Text>
                    </HStack>
                    <HStack>
                      <Switch
                        name="limpiaparabrisasAutoE"
                        onChange={handleChange}
                        isChecked={values.limpiaparabrisasAutoE}
                      />
                      <Text>Limpiaparabrisas</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.lucesAutoE}
                        name="lucesAutoE"
                      />
                      <Text>Luces, espejos y retrovisores</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.asientoAutoE}
                        name="asientoAutoE"
                      />
                      <Text>Asiento (sensor, trabas y estado)</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.cinturonSeguridadAutoE}
                        name="cinturonSeguridadAutoE"
                      />
                      <Text>Cinturon de Seguridad</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.aireAcondicionadoAutoE}
                        name="aireAcondicionadoAutoE"
                      />
                      <Text>Aire acondicionado / Calefacción</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.alarmasAutoE}
                        name="alarmasAutoE"
                      />
                      <Text>Alarmas de alertas</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.bocinaAutoE}
                        name="bocinaAutoE"
                      />
                      <Text>Bocina, alarma de retroceso</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.instrumentalAutoE}
                        name="instrumentalAutoE"
                      />
                      <Text>Instrumental / Comandos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.estructuraAutoE}
                        name="estructuraAutoE"
                      />
                      <Text>Estructura / Chasis</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.engraseAutoE}
                        name="engraseAutoE"
                      />
                      <Text>Engrase</Text>
                    </HStack>
                  </Stack>
                  <Stack placeSelf="start" pr={2}>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.proteccionesAutoE}
                        name="proteccionesAutoE"
                      />
                      <Text>
                        Protecciones eléctricas <br /> (cortacorrientes y pare
                        de emergencia)
                      </Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.sistemaElectricoAutoE}
                        name="sistemaElectricoAutoE"
                      />
                      <Text>Sistema electrico / Bateria</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.motorAutoE}
                        name="motorAutoE"
                      />
                      <Text>Motor</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.nivelAutoE}
                        name="nivelAutoE"
                      />
                      <Text>Nivel de fluidos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.sistemaHidraulicoAutoE}
                        name="sistemaHidraulicoAutoE"
                      />
                      <Text>Sistema hidraulico</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.transmisionAutoE}
                        name="transmisionAutoE"
                      />
                      <Text>Transmision</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.tapaAutoE}
                        name="tapaAutoE"
                      />
                      <Text>Tapa tanque de combustible</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.diferencialAutoE}
                        name="diferencialAutoE"
                      />
                      <Text>Diferencia / Cadenas</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.sistemaDireccionAutoE}
                        name="sistemaDireccionAutoE"
                      />
                      <Text>Sistema de direccion</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.bloqueosAutoE}
                        name="bloqueosAutoE"
                      />
                      <Text>Bloqueos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.finCarreraAutoE}
                        name="finCarreraAutoE"
                      />
                      <Text>Fin de carrera</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.limitadorAutoE}
                        name="limitadorAutoE"
                      />
                      <Text>Limitador de carga/vuelco</Text>
                    </HStack>
                  </Stack>
                  <Stack placeSelf="start">
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.ajusteTuercasAutoE}
                        name="ajusteTuercasAutoE"
                      />
                      <Text>Ajuste tuercas contrapeso</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.pernosAutoE}
                        name="pernosAutoE"
                      />
                      <Text>Pernos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.alemitesAutoE}
                        name="alemitesAutoE"
                      />
                      <Text>Alemites</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.cilindrosHidraulicosAutoE}
                        name="cilindrosHidraulicosAutoE"
                      />
                      <Text>Cilindros hidraulicos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.manguerasAutoE}
                        name="manguerasAutoE"
                      />
                      <Text>Mangueras, correas</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.valvulasAutoE}
                        name="valvulasAutoE"
                      />
                      <Text>Válvulas de Seguridad</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.frenosAutoE}
                        name="frenosAutoE"
                      />
                      <Text>Frenos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.ruedasAutoE}
                        name="ruedasAutoE"
                      />
                      <Text>
                        Ruedas (estado, <br /> presión, check points de tuercas,
                        piedras)
                      </Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.urrAutoE}
                        name="urrAutoE"
                      />
                      <Text>Uñas, rotulas y rodillos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.perdidasFluidoAutoE}
                        name="perdidasFluidoAutoE"
                      />
                      <Text>Perdidas fluidos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        isChecked={values.extintorAutoE}
                        name="extintorAutoE"
                      />
                      <Text>Extintor (carga, vencimiento, clavija)</Text>
                    </HStack>
                  </Stack>
                </HStack>
                <HStack w="100%" justifyContent="space-between" p={3}>
                  <Box></Box>
                  <FormControl w={"lg"}>
                    <FormLabel>Observaciones</FormLabel>
                    <Card
                      w="lg"
                      variant="unstyled"
                      bg="brand.fondos_secundarios"
                    >
                      <CardBody>
                        <Textarea
                          w="lg"
                          placeholder="Descripcion"
                          name="observacionesLum"
                          onChange={(e) => setObservaciones(e.target.value)}
                          onBlur={handleBlur}
                          maxLength="1500"
                          resize="none"
                        />
                      </CardBody>
                      <CardFooter placeSelf="end" color="brand.gris_medio">
                        {charCount}/1500
                      </CardFooter>
                    </Card>
                  </FormControl>
                  <Button
                    placeSelf={"end"}
                    fontSize={12}
                    fontWeight="semibold"
                    bg="brand.naranja"
                    color="white"
                    rightIcon={<MdNavigateNext fontSize="20px" />}
                    variant="solid"
                    form="autoelevador1"
                    onClick={handleSubmit}
                    isDisabled={loading}
                    _hover={{ bg: "white", color: "brand.naranja" }}
                  >
                    Finalizar
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </Stack>
      )}
    </Stack>
  );
};

export default RevisionAutoelevador;
