import React from "react";
import {
  HStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Stack,
} from "@chakra-ui/react";

const SegundoPasoRegistro = (props) => {
  const {
    handleChange,
    handleBlur,
    values,
    textAccionesInmediatas,
    setTextAccionesInmediatas,
    setFieldValue,
    usuarios,
  } = props;

  const handleCaracteres = (event, setFieldValue) => {
    const text = event.target.value;
    setTextAccionesInmediatas(text);
    setFieldValue("accionesRegistroEventos", text);
  };
  const charCount = textAccionesInmediatas.length;

  return (
    <Stack spacing={5} >
      <HStack w="100%" justifyContent="space-around" pb={3}>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Acciones Inmediatas</FormLabel>
          <Card w="xs" variant="unstyled" bg="brand.fondos_secundarios">
            <CardBody>
              <Textarea
                w="xs"
                placeholder="Descripcion"
                name="accionesRegistroEventos"
                onChange={(e) => handleCaracteres(e, setFieldValue)}
                value={values.accionesRegistroEventos}
                maxLength="250"
                resize="none"
              />
            </CardBody>
            <CardFooter placeSelf="end" color="brand.gris_medio">
              {charCount}/250
            </CardFooter>
          </Card>
        </FormControl>
        <FormControl w="2xs" isRequired placeSelf="start">
          <FormLabel fontWeight="semibold">
            Responsable Acción Inmediatas
          </FormLabel>
          <Input
            w="2xs"
            type="text"
            placeholder="Resp."
            name="responsableAccionRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.responsableAccionRegistroEventos}
          />
        </FormControl>
        <FormControl w="2xs" placeSelf="start">
          <FormLabel fontWeight="semibold">
            ¿El servicio quedó reestablecido?
          </FormLabel>
          <HStack>
            <Checkbox
              name="servicioReestRegistroEventos"
              onChange={handleChange}
              isChecked={values.servicioReestRegistroEventos}
            >
              SI
            </Checkbox>
          </HStack>
        </FormControl>
      </HStack>
      <HStack w="100%" justifyContent="space-around" pb={3}>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Detectó</FormLabel>
          <Select
            w="2xs"
            name="detectoRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.detectoRegistroEventos}
            placeholder="Seleccione detector"
          >
            {usuarios
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((usuario) => (
                <option value={usuario.idUsuario} key={usuario.idUsuario}>
                  {usuario.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Responsable registro</FormLabel>
          <Select
            w="2xs"
            name="responsableRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Seleccione responsable"
            value={values.responsableRegistroEventos}
          >
            {usuarios
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((usuario) => (
                <option value={usuario.idUsuario} key={usuario.idUsuario}>
                  {usuario.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha/Hora del registro</FormLabel>
          <Input
            w="2xs"
            type="datetime-local"
            name="fechaAccionRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fechaAccionRegistroEventos}
          />
        </FormControl>
      </HStack>
    </Stack>
  );
};

export default SegundoPasoRegistro;
