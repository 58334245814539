import React, { useEffect, useState } from "react";
import {
  Heading,
  Stack,
  HStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  CircularProgress,
  useToast,
  Box,
  IconButton,
  Text,
  Icon,
  Button,
} from "@chakra-ui/react";
import {
  MdArrowBack,
  MdAddCircle,
  MdOutlineImportExport,
} from "react-icons/md";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import EliminarAsociacion from "./EliminarAsociacion";
import { getAsociacionCSC } from "../../helpers/api.helper";
import EditarAsociacion from "./EditarAsociacion";
import { useSelector } from "react-redux";
import { sortData } from "../../../../utils/sortData";
import moment from "moment";

const TablaAsociacion = () => {
  const [loading, setLoading] = useState(true);
  const [asociacion, setAsociacion] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const navigate = useNavigate();
  const toast = useToast();
  const padLocacionACSC = useParams();
  const { user } = useSelector((state) => state.user);

  const initAsociaciones = async () => {
    setLoading(true);
    let res;
    res = await getAsociacionCSC(padLocacionACSC);
    if (res.status === 200) {
      setAsociacion(res.data);
    } else if (res.status === 204) {
      setLoading(false);
      return toast({
        title: "Exito",
        description: "No existen Asociaciones cargadas",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else if (res.status === 400) {
      toast({
        title: "Error",
        status: "error",
        description: "Error al cargar asociaciones",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  const handleColor = (e) => {
    const colorMapping = {
      true: "red.500",
      false: "green.500",
    };

    return colorMapping[e] || "#000";
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    initAsociaciones();
  }, []);

  return (
    <Stack w="100%" h="100%" overflow="auto">
      <HStack w="100%" px={5} justifyContent={"space-between"}>
        <HStack fontWeight="medium">
          <Box>
            <IconButton
              icon={<MdArrowBack />}
              variant="link"
              onClick={() => navigate(-1)}
              fontSize="24px"
            />
          </Box>
          <Heading
            fontWeight="medium"
            size="xl"
            fontFamily="fonts.title"
            color="brand.gris_primario"
          >
            Asociación Camión/Sandcubes
          </Heading>
          <Text fontSize="16px" placeSelf="end" letterSpacing={1}>
            en {padLocacionACSC.padLocacionACSC}
          </Text>
        </HStack>
        {(user.idRol === "admin" ||
          user.idRol === "coorOp" ||
          user.idRol === "userOperaciones") && (
          <Button
            color={"brand.naranja"}
            variant={"outline"}
            border={"1px solid brand.naranja"}
            size={"sm"}
            _hover={{ color: "white", bg: "brand.naranja" }}
            onClick={() => {
              navigate(
                `/operaciones/curso/asociacion/nueva/${padLocacionACSC.padLocacionACSC}`
              );
            }}
            leftIcon={<MdAddCircle />}
          >
            Agregar asociación
          </Button>
        )}
      </HStack>
      {loading && loadingtable()}
      {!loading && asociacion.length > 0 && (
        <TableContainer w="100%" h={"lg"} overflowY={"auto"} p={1}>
          <Table variant="simple" size="sm" bg="brand.fondo" borderRadius={10}>
            <Thead>
              <Tr textAlign="center">
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "camion.dominio",
                        asociacion
                      );
                      setSortField("camion.dominio");
                      setSortDirection(direction);
                      setAsociacion(sortedData);
                    }}
                    _hover={<Icon as={MdOutlineImportExport} />}
                  >
                    Dominio
                    {sortDirection === "asc" &&
                      sortField === "camion.dominio" && <ArrowUpIcon />}
                    {sortDirection === "des" &&
                      sortField === "camion.dominio" && <ArrowDownIcon />}
                  </span>
                  <span className="hover-icon" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon1").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon1").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "padLocacionACSC",
                        asociacion
                      );
                      setSortField("padLocacionACSC");
                      setSortDirection(direction);
                      setAsociacion(sortedData);
                    }}
                    _hover={<Icon as={MdOutlineImportExport} />}
                  >
                    ID Pad/Locación
                    {sortDirection === "asc" &&
                      sortField === "padLocacionACSC" && <ArrowUpIcon />}
                    {sortDirection === "des" &&
                      sortField === "padLocacionACSC" && <ArrowDownIcon />}
                  </span>
                  <span className="hover-icon1" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th>Sandcubes asociados</Th>
                <Th>Vuelta a base</Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon2").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon2").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "fechaCreacionACSC",
                        asociacion
                      );
                      setSortField("fechaCreacionACSC");
                      setSortDirection(direction);
                      setAsociacion(sortedData);
                    }}
                    _hover={<Icon as={MdOutlineImportExport} />}
                  >
                    Fecha y Hora
                    {sortDirection === "asc" &&
                      sortField === "fechaCreacionACSC" && <ArrowUpIcon />}
                    {sortDirection === "des" &&
                      sortField === "fechaCreacionACSC" && <ArrowDownIcon />}
                  </span>
                  <span className="hover-icon2" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon3").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon3").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "planta.nombrePlanta",
                        asociacion
                      );
                      setSortField("planta.nombrePlanta");
                      setSortDirection(direction);
                      setAsociacion(sortedData);
                    }}
                    _hover={<Icon as={MdOutlineImportExport} />}
                  >
                    Planta
                    {sortDirection === "asc" &&
                      sortField === "planta.nombrePlanta" && <ArrowUpIcon />}
                    {sortDirection === "des" &&
                      sortField === "planta.nombrePlanta" && <ArrowDownIcon />}
                  </span>
                  <span className="hover-icon3" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th>Estado</Th>
                {(user.idRol === "admin" ||
                  user.idRol === "coorOp" ||
                  user.idRol === "userOperaciones") && <Th>Acciones</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {asociacion.map((asociacion) => (
                <Tr key={asociacion.idACSC}>
                  <Td>{asociacion.camion.dominio}</Td>
                  <Td>{asociacion.padLocacionACSC}</Td>
                  <Td>
                    {asociacion.sandCube1
                      ? asociacion.sandCube1.nombreSandCube
                      : ""}{" "}
                    -{" "}
                    {asociacion.sandCube2
                      ? asociacion.sandCube2.nombreSandCube
                      : ""}
                    {asociacion.sandCube && asociacion.sandCube.idSandCube
                      ? ` - 
          ${asociacion.sandCube.nombreSandCube}`
                      : " "}
                  </Td>
                  <Td>{asociacion.vueltaBaseACSC ? "Si" : "No"}</Td>
                  <Td>
                    {moment
                      .utc(asociacion.fechaCreacionACSC)
                      .format("DD/MM/YYYY HH:mm")}
                  </Td>
                  <Td>
                    {asociacion.planta ? asociacion.planta.nombrePlanta : "-"}
                  </Td>
                  <Td color={handleColor(asociacion.eliminadoACSC)}>
                    {asociacion.eliminadoACSC ? "Finalizada" : "En curso"}
                  </Td>
                  {(user.idRol === "admin" ||
                    user.idRol === "coorOp" ||
                    user.idRol === "userOperaciones") && (
                    <Td>
                      <>
                        <EditarAsociacion
                          asociacion={asociacion}
                          initAsociaciones={initAsociaciones}
                        />

                        {!asociacion.eliminadoACSC && (
                          <EliminarAsociacion
                            asociacion={asociacion}
                            initAsociaciones={initAsociaciones}
                          />
                        )}
                      </>
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {!loading && asociacion.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay datos para mostrar
        </Heading>
      )}
    </Stack>
  );
};

export default TablaAsociacion;
