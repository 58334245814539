import React, { useEffect, useMemo } from "react";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { MdOutlineDrafts, MdOutlineMarkunread } from "react-icons/md";

const NotificationList = ({
  notificacionesNoLeidas,
  notificationReadTime,
  setNotificationReadTime,
  openModal,
  handleLeido,
}) => {
  const today = useMemo(() => moment(), []);

  const handleDif = (readTime) => {
    return notificacionesNoLeidas.filter((notification) => {
      const formattedNotification = moment
        .utc(notification.fechaEnvioNBS)
        .format("YYYY-MM-DD HH:mm:ss");
      const diffHoras = moment(today).diff(formattedNotification, "hours");
      return diffHoras <= readTime;
    });
  };

  const paginatedNotifications = useMemo(
    () => handleDif(notificationReadTime),
    [notificationReadTime, notificacionesNoLeidas, today]
  );

  const handleMoreNotificaciones = () => {
    setNotificationReadTime((prevTime) => {
      let newTime = prevTime;
      // let maxIterations = 10;
      // let iterations = 0;

      while (
        handleDif(newTime).length === 0 ||
        handleDif(newTime).length === handleDif(prevTime).length
      ) {
        newTime += 8;
        // iterations++;
      }

      return newTime;
    });
  };

  useEffect(() => {
    console.log("Notifications updated:", paginatedNotifications);
  }, [paginatedNotifications]);

  return (
    <>
      {paginatedNotifications.length > 0 &&
        paginatedNotifications?.map((elem) => (
          <Card
            key={elem?.idnotificacionesBS}
            size={"sm"}
            onClick={() => openModal(elem)}
            variant="unstyled"
            bg={elem.leidoNBS === false ? "brand.fondos_secundarios" : "white"}
            p={2}
            cursor={"pointer"}
          >
            <CardHeader key={elem.idnotificacionesBS}>
              <HStack justifyContent={"space-between"} borderRadius={5}>
                <Heading
                  size={"md"}
                  fontWeight={"semibold"}
                  color={"gray.600"}
                  textAlign={"left"}
                >
                  {elem.tituloNBS}
                </Heading>
                <IconButton
                  variant={"link"}
                  icon={
                    elem.leidoNBS === false ? (
                      <MdOutlineMarkunread onClick={() => handleLeido(elem)} />
                    ) : (
                      <MdOutlineDrafts />
                    )
                  }
                />
              </HStack>
            </CardHeader>
            <CardBody>
              <Heading size={"sm"} color={"gray"} fontWeight={"normal"}>
                {elem.descripciónNBS}
              </Heading>
            </CardBody>
          </Card>
        ))}
      {notificacionesNoLeidas?.length > 8 &&
        notificacionesNoLeidas?.length -
          handleDif(notificationReadTime).length !==
          0 && (
          <Button
            onClick={() => handleMoreNotificaciones()}
            variant="outline"
            backgroundColor={"brand.naranja"}
            color={"white"}
            fontFamily={"fonts.text"}
            fontSize={"sm"}
            letterSpacing={0.5}
            w="100%"
            mt={2}
            padding={2}
            _hover={{
              bg: "brand.fondos_primarios",
              color: "brand.naranja",
            }}
          >
            Ver mas notificaciones
          </Button>
        )}{" "}
    </>
  );
};

export default NotificationList;
